//@ts-check
import React, { memo, useCallback, useEffect, useState } from 'react'
import { assoc, assocPath, init, prop } from 'ramda'
import BrainOnV2 from 'assets/svg/BrainOnV2'
import {
  getEvaluationsByStudentIds,
  createEvaluation,
  updateEvaluation
} from 'api/evaluations'
import { getSubjectById } from 'api/subjects'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import useSubjects from 'hooks/useSubjects'
import Modal from 'components/modals/Modal'
import { H2, H5, Paragraph } from 'components/typography'
import Select from 'components/selects/Select'
import Radio from 'components/inputs/Radio'
import TextInput from 'components/inputs/TextInput'
import Button from 'components/buttons/Button'
import DotsSpinner from 'components/spinners/DotsSpinner'
import Tabs from 'components/tabs/Tabs'
import styles from './EvalAdvisor.module.css'

function makeSubjectLabel(subject = {}) {
  let label = subject.name
  if (subject?.level) label += ' de ' + subject.level
  if (subject?.sublevel) label += '(' + subject.sublevel + ')'

  return label
}

const initialState = {
  selectedStudentId: '',
  currentEvaluation: null,
  evaluations: [],
  isFetching: false,
  showModal: false,
  isSaving: false
}

function EvalAdvisor({ students = [] }) {
  const [
    {
      selectedStudentId,
      currentEvaluation,
      evaluations,
      isFetching,
      showModal,
      isSaving
    },
    setState
  ] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  const { teacher } = useAuth()
  const {
    subjects,
    subjectsById,
    isFetching: isFetchingSubjects,
    fetchSubjects
  } = useSubjects()

  const handleStudentChange = e => {
    const targetStudentId = e.target.value
    if (!targetStudentId)
      return setState(prevState => ({
        ...prevState,
        selectedStudentId: '',
        currentEvaluation: null
      }))
    const currentEvaluation =
      evaluations.find(({ studentId }) => targetStudentId === studentId) || {}
    const targetStudent = students.find(({ _id }) => targetStudentId === _id)

    const { teacherEvaluations } = currentEvaluation
    const isFirstEvaluation =
      !teacherEvaluations || teacherEvaluations.length === 0

    setState(prevState => ({
      ...prevState,
      selectedStudentId: targetStudentId,
      currentEvaluation: {
        ...currentEvaluation,
        teacherEvaluations: isFirstEvaluation
          ? [
              {
                isNew: true,
                teacherId: teacher.id,
                subjectId: targetStudent.attendance.subjectId,
                level: undefined,
                service: undefined
              }
            ]
          : teacherEvaluations
      }
    }))
  }

  const handleSubjectChange = (e, index = 0) => {
    setState(
      assocPath(
        ['currentEvaluation', 'teacherEvaluations', index, 'subjectId'],
        e.target.value
      )
    )
  }

  const handleLevelChange = (e, index = 0) => {
    const { name, value } = e.target
    if (value === '' || (Number(value) >= 1 && Number(value) <= 10))
      setState(
        assocPath(
          ['currentEvaluation', 'teacherEvaluations', index, name],
          value ? Number(value) : undefined
        )
      )
  }

  const handleServiceChange = (e, index = 0) => {
    setState(
      assocPath(
        ['currentEvaluation', 'teacherEvaluations', index, 'service'],
        e.target.value ? e.target.name : ''
      )
    )
  }

  const handleAddNewTab = () => {
    setState(prevState => ({
      ...prevState,
      currentEvaluation: {
        ...currentEvaluation,
        teacherEvaluations: [
          ...prevState.currentEvaluation.teacherEvaluations,
          {
            isNew: true,
            teacherId: teacher.id,
            subjectId: null,
            level: undefined,
            service: undefined
          }
        ]
      }
    }))
  }

  const handleOpenModal = () => {
    fetchSubjects()
    setState(assoc('showModal', true))
  }
  const handleClose = () => setState(initialState)

  const handleSave = () => {
    setState(assoc('isSaving', true))
    if (currentEvaluation.id)
      return updateEvaluation(currentEvaluation.id, {
        teacherEvaluations: currentEvaluation.teacherEvaluations
      })
        .then(() => {
          setState(initialState)
          setSuccessMessage({ message: 'Evaluación actualizada correctamente' })
        })
        .catch(e => setErrorMessage({ message: e.message }))
        .finally(() => setState(assoc('isSaving', false)))
    createEvaluation({
      studentId: selectedStudentId,
      teacherEvaluations: currentEvaluation.teacherEvaluations
    })
      .then(() => {
        setState(initialState)
        setSuccessMessage({ message: 'Evaluación creada correctamente' })
      })
      .catch(e => setErrorMessage({ message: e.message }))
      .finally(() => setState(assoc('isSaving', false)))
  }

  const fetchData = useCallback(() => {
    setState(assoc('isFetching', true))
    getEvaluationsByStudentIds({ studentIds: students.map(prop('_id')) })
      .then(evaluations => setState(assoc('evaluations', evaluations)))
      .catch(e => {
        setErrorMessage({ message: e.message })
      })
      .finally(() => setState(assoc('isFetching', false)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrorMessage, students, showModal])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const canSend = () => {
    let aux = true
    currentEvaluation?.teacherEvaluations?.forEach(evaluation => {
      if (!evaluation?.level || !evaluation?.service || !evaluation?.subjectId)
        aux = false
    })
    return aux && !!currentEvaluation
  }
  return (
    <div className={styles.evalAdvisor}>
      <BrainOnV2 className={styles.svg} onClick={handleOpenModal} />
      {showModal && (
        <Modal onCancel={handleClose} showActions={false} showCloseIcon>
          <div className={styles.modal}>
            <H2>Evaluación prueba</H2>
            <Paragraph>
              Selecciona al alumno y rellena con tu evaluación
            </Paragraph>
            <Select
              placeholder='Selecciona un alumno'
              valueKey='_id'
              labelKey='name'
              value={selectedStudentId}
              options={students}
              onChange={handleStudentChange}
              isLoading={isFetching}
              disabled={isFetching}
              autofocus
            />
            {selectedStudentId && (
              <Tabs
                initialTab={Math.max(
                  currentEvaluation.teacherEvaluations.findIndex(
                    ({ isNew }) => !!isNew
                  ),
                  0
                )}
                tabs={[...currentEvaluation.teacherEvaluations, {}]?.map(
                  (evaluation, i) => {
                    const hasNew = currentEvaluation.teacherEvaluations.find(
                      ({ isNew }) => !!isNew
                    )
                    if (currentEvaluation.teacherEvaluations.length === i)
                      return {
                        title: '+',
                        content: '',
                        disabled: !!hasNew,
                        onClick: !hasNew && handleAddNewTab
                      }

                    const isOwner = evaluation.teacherId === teacher.id
                    return {
                      title: evaluation.isNew
                        ? 'Nueva evaluación'
                        : isOwner
                        ? 'Tu evaluación'
                        : 'Otra evaluación',
                      content: (
                        <CommonForm
                          key={i}
                          level={evaluation.level}
                          service={evaluation.service}
                          subjectId={evaluation.subjectId}
                          subjects={
                            evaluation.isNew
                              ? subjects.filter(
                                  ({ id }) =>
                                    !init(
                                      currentEvaluation.teacherEvaluations.map(
                                        ({ subjectId }) => subjectId
                                      )
                                    ).includes(id)
                                )
                              : [subjectsById[evaluation.subjectId] || {}]
                          }
                          isFetchingSubjects={isFetchingSubjects}
                          onSubjectChange={e => handleSubjectChange(e, i)}
                          onLevelChange={e => handleLevelChange(e, i)}
                          onServiceChange={e => handleServiceChange(e, i)}
                          disabled={!isOwner}
                        />
                      )
                    }
                  }
                )}
              />
            )}

            <Button
              label='Evaluar'
              size='small'
              className={styles.evalButton}
              disabled={isSaving || !canSend()}
              loading={isSaving}
              onClick={handleSave}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default memo(EvalAdvisor)

function SubjectLabel({ subjectId }) {
  const [subject, setSubject] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(true)
    getSubjectById(subjectId)
      .then(setSubject)
      .catch(() => {
        setSubject({ name: '¡Atención! - Asignatura desconocida' })
      })
      .finally(() => setIsLoading(false))
  }, [subjectId])
  return isLoading ? (
    <DotsSpinner />
  ) : (
    <Paragraph className={styles.subjectLabel}>
      {makeSubjectLabel(subject)}
    </Paragraph>
  )
}

function CommonForm({
  level,
  service,
  subjectId,
  subjects = [],
  isFetchingSubjects = false,
  disabled = false,
  onSubjectChange,
  onLevelChange,
  onServiceChange
}) {
  return (
    <div className={styles.commonForm}>
      <H5>Nivel estimado (1 - 10)</H5>
      <div className={styles.subjectInfo}>
        {disabled ? (
          <SubjectLabel subjectId={subjectId} />
        ) : (
          <Select
            placeholder='Selecciona una asignatura'
            value={subjectId}
            options={subjects}
            disabled={disabled}
            isLoading={isFetchingSubjects}
            onChange={onSubjectChange}
            isCleareable={false}
            maxMenuHeight={180}
          />
        )}
        <TextInput
          className={styles.subjectLevel}
          placeholder='Nivel'
          name='level'
          value={level}
          type='number'
          disabled={disabled}
          onChange={onLevelChange}
        />
      </div>

      <H5>Recomendación</H5>
      <div className={styles.recommendation}>
        <Radio
          label='Tarifa normal'
          name='normal'
          checked={service === 'normal'}
          disabled={disabled}
          onCheck={onServiceChange}
        />
        <Radio
          label='Tarifa mañanas'
          name='mornings'
          checked={service === 'mornings'}
          disabled={disabled}
          onCheck={onServiceChange}
        />
        <Radio
          label='Pack de horas'
          name='pack'
          checked={service === 'pack'}
          disabled={disabled}
          onCheck={onServiceChange}
        />
        <Radio
          label='Otros'
          name='others'
          checked={service === 'others'}
          disabled={disabled}
          onCheck={onServiceChange}
        />
      </div>
    </div>
  )
}
