import { GET, POST, PUT } from './index'

export const getEvaluationsByStudentIds = ({ studentIds }) =>
  GET({
    url: 'teachers/evaluations/get-by-studentIds?studentIds=' + studentIds
  })

export const createEvaluation = (data = {}) =>
  POST({
    url: 'teachers/evaluations/',
    data
  })
export const updateEvaluation = (id = '', data = {}) =>
  PUT({
    url: 'teachers/evaluations/' + id,
    data
  })
