//@ts-check
import React from 'react'
import Lightbulb from 'assets/icons/Lightbulb'
import { noop } from 'utils'
import styles from './Lightbulb.module.css'

export const lightbulbStatusId = {
  OFF: 'OFF',
  ON: 'ON',
  STANDBY: 'STANDBY'
}

export const lightbulbColorByStatus = {
  OFF: 'var(--bright)',
  ON: 'var(--seadapted)',
  STANDBY: 'var(--sandground)'
}

function ClassroomLightbulb({
  classNames = { container: '', innerContainer: '' },
  status,
  disabled = false,
  onClick = noop
}) {
  return (
    <div
      className={[styles.lightbulb, classNames?.container || ''].join(' ')}
      onClick={() => !disabled && onClick()}
    >
      <div
        className={[
          styles.innerContainer,
          status === lightbulbStatusId.ON ? styles.on : '',
          classNames?.innerContainer || ''
        ].join(' ')}
        data-status={disabled}
      >
        <Lightbulb color={lightbulbColorByStatus[status]} />
      </div>
    </div>
  )
}

export default ClassroomLightbulb
