import React, { memo, useEffect, useMemo, useState } from 'react'
import { prop, uniq } from 'ramda'
import { noop } from 'utils'
import { findBrainNotebooks } from 'api/brain'
import Button from 'components/buttons/Button'
import Select from '../Select'
import styles from './NotebookSelect.module.css'

const customStyles = {
  select: styles.customSelect
}

function NotebookSelect({ selectedNotebookCategory, onSelect = noop }) {
  const [isFetching, setIsFetching] = useState(false)
  const [resource, setResource] = useState({
    category: selectedNotebookCategory,
    element: ''
  })
  const [notebooks, setNotebooks] = useState([])
  const handleLoadPDF = () => {
    if (resource.element) return onSelect(resource.element, resource.category)
  }
  useEffect(() => {
    setIsFetching(true)
    findBrainNotebooks()
      .then(setNotebooks)
      .catch(console.error)
      .finally(() => setIsFetching(false))
  }, [])

  const subjectOptions = useMemo(
    () => uniq(notebooks.map(prop('category'))).sort(),
    [notebooks]
  )
  const notebookOptions = useMemo(
    () => notebooks.filter(notebook => notebook.category === resource.category),
    [notebooks, resource.category]
  )
  return (
    <div className={styles.notebookSelect}>
      <Select
        label='Asignatura'
        placeholder='Selecciona asignatura'
        isLoading={isFetching}
        options={subjectOptions}
        value={resource.category}
        onChange={e => setResource({ category: e.target.value, element: '' })}
        customStyles={customStyles}
        transformList
      />
      <Select
        label='Cuadernillo'
        placeholder='Selecciona cuadernillo'
        options={notebookOptions}
        valueKey='url'
        labelKey='name'
        value={resource.element}
        disabled={!resource.category}
        onChange={e => setResource({ ...resource, element: e.target.value })}
        customStyles={customStyles}
      />
      <div className={styles.button}>
        <Button
          label='Cargar cuadernillo'
          size='small'
          type='accent-primary'
          disabled={!resource.element}
          onClick={handleLoadPDF}
        />
      </div>
    </div>
  )
}

export default memo(NotebookSelect)
