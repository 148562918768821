import * as React from 'react'
const Grid = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path stroke={props.color || '#000'} d='M0 0H0v20h20V0Z' />
    <path stroke={props.color || '#000'} d='M10 0V20 M20 0 V20' />
    <path stroke={props.color || '#000'} d='M0 10 H20 M0 20 H20' />
  </svg>
)
export default Grid
