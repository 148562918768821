import * as React from 'react'
const Dotted = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path stroke={props.color || '#000'} d='M20 0H0v20h20V0Z' />
    <circle cx='5' cy='5' r='1' fill='black' />
    <circle cx='5' cy='10' r='1' fill='black' />
    <circle cx='5' cy='15' r='1' fill='black' />
    <circle cx='15' cy='5' r='1' fill='black' />
    <circle cx='15' cy='10' r='1' fill='black' />
    <circle cx='15' cy='15' r='1' fill='black' />
  </svg>
)
export default Dotted
