import * as React from 'react'

const ChevronBottom = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g opacity={props.disabled ? 0.24 : undefined}>
      <path
        d='m15 17.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L15 17.586Z'
        fill={props.color || '#8080F8'}
      />
    </g>
  </svg>
)

export default ChevronBottom
