//@ts-check
import React, { useCallback, useEffect, useRef } from 'react'
import { noop } from 'utils'
import Cross from 'assets/icons/Cross'
import Button from 'components/buttons/Button'
import styles from './Modal.module.css'

function Modal({
  children = null,
  showActions = true,
  okText = 'Aceptar',
  cancelText = 'Cancelar',
  hideOkButton = false,
  hideCancelButton = false,
  otherAction = null,
  isLoading = false,
  okDisabled = false,
  cancelDisabled = false,
  showCloseIcon = false,
  useFocus = true,
  onOk = noop,
  onCancel = noop
}) {
  const modalRef = useRef(null)
  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') onCancel()
    },
    [onCancel]
  )
  useEffect(() => {
    useFocus && modalRef.current?.focus()
  }, [useFocus])

  return (
    <div
      ref={modalRef}
      className={styles.modal}
      onKeyDown={handleKeyDown}
      tabIndex={101}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalContent}>
          {showCloseIcon && (
            <Cross
              className={styles.closeIcon}
              color='var(--dark-grey-40)'
              onClick={onCancel}
            />
          )}
          {children}
        </div>
        {showActions && (
          <div className={styles.modalActions}>
            {!hideCancelButton && (
              <Button
                type='secondary'
                size='tiny'
                label={cancelText}
                onClick={onCancel}
                disabled={cancelDisabled}
              />
            )}
            {otherAction}
            {!hideOkButton && (
              <Button
                size='tiny'
                label={okText}
                onClick={onOk}
                loading={isLoading}
                disabled={isLoading || okDisabled}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
