//@ts-check
import React, { useRef, useState, useEffect } from 'react'
import { prop, sortBy } from 'ramda'
import styles from './AttendanceHourSelector.module.css'

const TAB_WIDTH = 180

function AttendanceHourSelector({
  selectedHour,
  attendancesByHour,
  renderTabContent,
  onSelectHour
}) {
  const targetHours = Object.keys(attendancesByHour).sort()
  const [activeTab, setActiveTab] = useState(null)
  const tabListRef = useRef(null)

  const handleTabClick = index => {
    setActiveTab(index)
    onSelectHour(targetHours[index])
  }

  const handleNextTab = () => {
    const nextTab = Math.min(activeTab + 1, targetHours.length - 1)
    setActiveTab(() => {
      tabListRef.current.scrollLeft = nextTab * TAB_WIDTH
      return nextTab
    })
    onSelectHour(targetHours[nextTab])
  }

  const handlePrevTab = () => {
    const prevTab = Math.max(activeTab - 1, 0)
    setActiveTab(() => {
      tabListRef.current.scrollLeft = prevTab * TAB_WIDTH
      return prevTab
    })
    onSelectHour(targetHours[prevTab])
  }

  const renderTabLabel = ({ stringHour, attendances }) => {
    const [_hour, _minutes] = stringHour.split(':')
    return (
      <div className={styles.tabLabel}>
        {`${stringHour} - ${Number(_hour) + 1}:${_minutes}  ${
          attendances.length ? `(${attendances.length})` : ''
        }`}
      </div>
    )
  }

  useEffect(() => {
    tabListRef.current.scrollLeft = activeTab * TAB_WIDTH
  }, [activeTab])
  useEffect(() => {
    if (activeTab === null && targetHours.length > 0)
      setActiveTab(targetHours.findIndex(s => s === selectedHour))
  }, [activeTab, selectedHour, targetHours])
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabNavigation}>
        <div className={styles.arrowLeft} onClick={handlePrevTab}>
          &lt;
        </div>
        <div className={styles.tabList} ref={tabListRef}>
          {targetHours.map((stringHour, index) => (
            <div
              key={index}
              className={[
                styles.tab,
                index === activeTab ? styles.activeTab : ''
              ].join(' ')}
              onClick={() => handleTabClick(index)}
            >
              {renderTabLabel({
                stringHour,
                attendances: attendancesByHour[stringHour] || []
              })}
            </div>
          ))}
        </div>
        <div>
          <div className={styles.arrowRight} onClick={handleNextTab}>
            &gt;
          </div>
        </div>
      </div>

      <div className={styles.tabContent}>
        {renderTabContent({
          attendances: sortBy(
            prop('classStart'),
            attendancesByHour[targetHours[activeTab]] || []
          )
        })}
      </div>
    </div>
  )
}

export default AttendanceHourSelector
