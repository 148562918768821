//@ts-check
import React, { useState } from 'react'
import Empty from 'assets/icons/Empty'
import Grid from 'assets/icons/Grid'
import Dotted from 'assets/icons/Dotted'
import Lined from 'assets/icons/Lined'
import LinedVerticalLine from 'assets/icons/LinedVerticalLine'
import styles from './BackgroundToolbar.module.css'

export const classroomBgdToolbarIds = {
  emptyNotebook: 'emptyNotebook',
  gridNotebook: 'gridNotebook',
  dottedNotebook: 'dottedNotebook',
  linedNotebook: 'linedNotebook',
  linedNotebookVerticalLine: 'linedNotebookVerticalLine'
}
const OPTIONS = [
  {
    id: classroomBgdToolbarIds.emptyNotebook,
    label: 'Fondo vacío',
    Icon: Empty
  },
  {
    id: classroomBgdToolbarIds.gridNotebook,
    label: 'Grilla',
    Icon: Grid
  },
  {
    id: classroomBgdToolbarIds.dottedNotebook,
    label: 'Puntos',
    Icon: Dotted
  },
  {
    id: classroomBgdToolbarIds.linedNotebook,
    label: 'Líneas',
    Icon: Lined
  },
  {
    id: classroomBgdToolbarIds.linedNotebookVerticalLine,
    label: 'Líneas con línea vertical',
    Icon: LinedVerticalLine
  }
]

/**
 * @typedef ClassroomToolbarProps
 * @property {'emptyNotebook' | 'gridNotebook' | 'dottedNotebook' | 'linedNotebook' | 'linedNotebookVerticalLine'} selectedOption
 * @property {(id: string)=>void} onSelect
 */

/**
 *
 * @param {ClassroomToolbarProps} props
 */

function BackgroundToolbar({ selectedOption, onSelect }) {
  const [open, setOpen] = useState(false)

  const handleSelect = id => {
    setOpen(false)
    onSelect(id)
  }
  const { label, Icon } =
    OPTIONS.find(({ id }) => id === selectedOption) || OPTIONS[0]
  if (!open)
    return (
      <div className={styles.toolbar} onClick={() => setOpen(true)}>
        <div className={styles.iconContainer} title={label}>
          <Icon color='var(--dark-color)' />
        </div>
      </div>
    )
  return (
    <div className={styles.toolbar}>
      {OPTIONS.map(({ id, label, Icon }) => {
        return (
          <div
            key={id}
            className={[
              styles.iconContainer,
              id !== selectedOption ? styles.unfocused : ''
            ].join(' ')}
            onClick={() => handleSelect(id)}
            title={label}
          >
            <Icon color='var(--dark-color)' />
          </div>
        )
      })}
    </div>
  )
}

export default BackgroundToolbar
