import * as React from 'react'
const Empty = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 50 50'
    fill='none'
    {...props}
  >
    <g stroke={props.color || '#000'}>
      <path d='M50 0H0v50h50V0Z' />
    </g>
  </svg>
)
export default Empty
