import * as React from 'react'
const Marker = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 19 20'
    fill='none'
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      stroke={props.color || '#fff'}
      strokeWidth={0.5}
      d='M3.205 17.783H1.474l-.838-.838L2.59 14.99l.45.451-1.503 1.504.201.2h1.203l.902-.902.45.45-1.088 1.09ZM.886 18.756h9.92v.638H.887v-.638Z'
    />
    <path
      fill={props.color || '#fff'}
      stroke={props.color || '#fff'}
      strokeWidth={0.5}
      d='M4.394 17.245 2.039 14.89l.225-.225a2.022 2.022 0 0 0 0-2.857l.452-.45 5.21 5.21-.45.452a2.006 2.006 0 0 0-1.428-.592c-.54 0-1.047.21-1.428.592l-.226.225Zm-1.467-2.368 1.48 1.48a2.638 2.638 0 0 1 1.847-.558l-2.77-2.77a2.654 2.654 0 0 1-.557 1.848Z'
    />
    <path
      fill={props.color || '#fff'}
      stroke={props.color || '#fff'}
      strokeWidth={0.5}
      d='M7.701 17.246 2.04 11.584 11.223 2.4l2.455 2.455-2.004 2.005.752.752 2.004-2.005 2.455 2.455-9.184 9.184Zm-4.76-5.662 4.76 4.76 8.282-8.282L14.43 6.51l-2.004 2.005-1.654-1.654 2.004-2.005-1.553-1.553-8.282 8.282Z'
    />
    <path
      fill={props.color || '#fff'}
      stroke={props.color || '#fff'}
      strokeWidth={0.5}
      d='M16.434 8.513 13.98 6.058l1.002-1.002-.752-.752-1.002 1.002-2.455-2.455.927-.927A3.134 3.134 0 0 1 13.929 1c.842 0 1.634.328 2.23.924l1.202 1.202c.595.596.924 1.388.924 2.23 0 .842-.328 1.634-.924 2.23l-.927.927Zm-1.553-2.455 1.553 1.553.476-.476a2.5 2.5 0 0 0 .737-1.779 2.5 2.5 0 0 0-.737-1.778l-1.202-1.203a2.498 2.498 0 0 0-1.78-.737 2.5 2.5 0 0 0-1.778.737l-.476.476 1.553 1.553 1.003-1.002 1.653 1.654-1.002 1.002Z'
    />
  </svg>
)
export default Marker
