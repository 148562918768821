import { GET } from './index'
import config from 'config'

/**
 *
 * @param {{full: boolean}} data
 */
export const fetchMe = (data = {}) => {
  return GET({ url: 'teachers/account/me' + (data.full ? '/full' : '') })
}

export const googleLogin = credentials => {
  return fetch(config.google.authUrl + '/api/auth/classfy-teacher-login', {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    const JSONResponse = await response.json()
    if (response.ok) return JSONResponse.body
    throw new Error(JSONResponse.message || COMMON_ERROR_MESSAGE)
  })
}
