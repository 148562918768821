//@ts-check
import React, { useState } from 'react'
import { noop } from 'utils'
import styles from './Tabs.module.css'
/**
 * @typedef TabsProps
 * @property {number} initialTab
 * @property {{title:string, content:any, disabled?:boolean, onClick?: ()=> void}[]} tabs
 */
/** @param {TabsProps} props*/
function Tabs({
  initialTab = 0,
  tabs = [
    { title: 'Tab 1', content: 'Contenido', disabled: false, onClick: noop }
  ]
}) {
  const [activeTab, setActiveTab] = useState(initialTab)

  const handleTabClick = index => {
    setActiveTab(index)
  }

  return (
    <div>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={[
              styles.tab,
              activeTab === index ? styles.active : '',
              tab.disabled ? styles.disabled : ''
            ].join(' ')}
            onClick={() => {
              if (tab.disabled) return
              handleTabClick(index)
              tab.onClick && tab.onClick()
            }}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>{tabs[activeTab].content}</div>
    </div>
  )
}

export default Tabs
