import * as React from 'react'
const LinedVerticalLine = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 50 50'
    fill='none'
    {...props}
  >
    <path stroke={props.color || '#000'} d='M50 0H0v50h50V0Z' />
    <path stroke='red' d='M10 0v50' />
    <path stroke={props.color || '#000'} d='M0 10h50M0 20h50M0 30h50M0 40h50' />
  </svg>
)
export default LinedVerticalLine
