import * as React from 'react'
const Timer = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='49%' cy='54.5%' r='9.5' fill='white' />
    <path
      fill={props.color ? props.color : '#8080F8'}
      d='M6.59 22.174a10.69 10.69 0 0 1-5.249-4.616 10.327 10.327 0 0 1-1.198-6.8A10.466 10.466 0 0 1 3.51 4.69a10.9 10.9 0 0 1 6.485-2.727v-.423h-1.05a.792.792 0 0 1-.684-.385.755.755 0 0 1 0-.77A.792.792 0 0 1 8.944 0h3.68c.281 0 .542.147.682.385a.755.755 0 0 1 0 .771.792.792 0 0 1-.682.385h-1.052v.423c2.084.152 4.078.892 5.739 2.131a10.55 10.55 0 0 1 3.616 4.848c.7 1.925.811 4.007.322 5.992a10.481 10.481 0 0 1-3.079 5.191 10.87 10.87 0 0 1-5.48 2.706 11.004 11.004 0 0 1-6.1-.658Zm-5.005-9.705a8.89 8.89 0 0 0 2.694 6.358 9.308 9.308 0 0 0 6.505 2.633c2.44 0 4.779-.947 6.504-2.633a8.89 8.89 0 0 0 2.694-6.358c0-2.385-.969-4.672-2.694-6.357a9.307 9.307 0 0 0-6.504-2.634 9.317 9.317 0 0 0-6.502 2.637 8.898 8.898 0 0 0-2.697 6.354Zm19.07-6.097-2.603-2.544a.76.76 0 0 1-.199-.742.78.78 0 0 1 .555-.542c.27-.072.56.002.76.194l2.601 2.544c.2.194.277.478.204.744a.78.78 0 0 1-.557.545.8.8 0 0 1-.762-.2Z'
    />
    {props.children}
  </svg>
)
export default Timer
