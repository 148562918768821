import { GET, POST } from './index'

export const findTemporaryNotebooks = () =>
  GET({
    url: 'teachers/files'
  })

/**
 *
 * @param {{file: File, studentId: string}} data
 */
export const uploadPDF = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return POST({
    url: 'teachers/files/upload-pdf',
    data: formData,
    useAcceptAPP_JSON: true
  })
}
