import React from 'react'
import { noop } from 'utils'
import styles from './TextInput.module.css'

function TextInput({
  className = '',
  name = '',
  placeholder = '',
  value = '',
  disabled = false,
  error = false,
  type = 'text',
  required = false,
  onChange = noop
}) {
  return (
    <input
      className={[
        styles.input,
        disabled ? styles.disabled : '',
        error ? styles.error : '',
        className
      ].join(' ')}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      required={required}
      onChange={onChange}
    />
  )
}

export default TextInput
