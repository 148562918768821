import { POST } from './index'

/**
 *
 * @param {{photo: File}} data
 */
export const uploadTeacherProfilePicture = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return POST({
    url: 'teachers/account/profile-picture',
    data: formData,
    useAcceptAPP_JSON: true
  })
}
