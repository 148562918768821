//@ts-check
import Back from 'assets/icons/Back'
import EraseAll from 'assets/icons/EraseAll'
import Eraser from 'assets/icons/Eraser'
import Forward from 'assets/icons/Forward'
import Pencil from 'assets/icons/Pencil'
import StraightLine from 'assets/icons/StraightLine'
import Type from 'assets/icons/Type'
import React, { useState } from 'react'
import styles from './Toolbar.module.css'

export const brushColors = [
  '#1a1a1a',
  '#f15a29',
  '#8dc63f',
  '#4682b4',
  '#fbbc40'
]

export const classroomToolbarIds = {
  draw: 'draw',
  line: 'line',
  type: 'type',
  erase: 'erase',
  pop: 'pop',
  push: 'push',
  eraseAll: 'eraseAll'
}
const OPTIONS = [
  {
    id: classroomToolbarIds.draw,
    label: 'Dibujar - doble click para más colores',
    Icon: Pencil
  },
  { id: classroomToolbarIds.line, label: 'Línea', Icon: StraightLine },
  { id: classroomToolbarIds.type, label: 'Escribir', Icon: Type },
  { id: classroomToolbarIds.erase, label: 'Borrar', Icon: Eraser },
  // { id: classroomToolbarIds.pop, label: 'Deshacer', Icon: Back },
  // { id: classroomToolbarIds.push, label: 'Rehacer', Icon: Forward },
  {
    id: classroomToolbarIds.eraseAll,
    label: 'Limpiar todo',
    Icon: EraseAll
  }
]
/**
 * @typedef ClassroomToolbarProps
 * @property {'draw' | 'type' | 'erase' | 'pop' | 'push' | 'eraseAll'} selectedOption
 * @property {string[]} [optionIdsToHide]
 * @property {string} [brushColor]
 * @property {(id: string)=>void} onClick
 * @property {(color: string)=>void} [onSetColor]
 */

/**
 *
 * @param {ClassroomToolbarProps} props
 */

function ClassroomToolbar({
  selectedOption = 'draw',
  optionIdsToHide = [],
  brushColor = brushColors[0],
  onClick = () => 'draw',
  onSetColor = () => brushColors[0]
}) {
  const [showBrushColors, setShowBrushColors] = useState(false)
  return (
    <div className={styles.toolbar}>
      {OPTIONS.filter(({ id }) => !optionIdsToHide.includes(id)).map(
        ({ id, label, Icon }) => {
          const isDrawTool =
            [classroomToolbarIds.draw, classroomToolbarIds.line].includes(id) &&
            id === selectedOption
          return (
            <div
              key={id}
              className={[
                styles.iconContainer,
                id !== selectedOption ? styles.unfocused : ''
              ].join(' ')}
              onClick={() => onClick(id)}
              onDoubleClick={() => isDrawTool && setShowBrushColors(true)}
              onTouchEnd={() => isDrawTool && setShowBrushColors(true)}
              title={label}
            >
              <Icon color={isDrawTool ? brushColor : 'var(--dark-color)'} />
            </div>
          )
        }
      )}
      {showBrushColors && (
        <div className={styles.brushColors}>
          {brushColors.map(color => (
            <div
              key={color}
              style={{ background: color }}
              className={styles.brushColor}
              onClick={() => {
                onSetColor(color)
                setShowBrushColors(false)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ClassroomToolbar
