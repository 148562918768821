//@ts-check
import React, { useCallback } from 'react'
import useWebRTC from 'hooks/useWebRTC'
import useNavigation from 'hooks/useNavigation'
import { useNotificationActions } from 'context/NotificationProvider'
import PrivateRoute from 'components/PrivateRoute'
import styles from './Layout.module.css'

function AcademyLayout() {
  const navigate = useNavigation()
  const { setErrorMessage } = useNotificationActions()
  const handleError = useCallback(
    (message = '') => {
      console.error('[handleError] - Error executing WebRTC:', message)
      setErrorMessage({ message })
      navigate('/')
    },
    [navigate, setErrorMessage]
  )

  const context = useWebRTC(handleError)
  return (
    <main className={styles.main}>
      <PrivateRoute context={context} />
    </main>
  )
}

export default AcademyLayout
