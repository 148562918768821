import * as React from 'react'

const BrainOn = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 38 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.14 32.28c8.914 0 16.14-7.226 16.14-16.14C32.28 7.226 25.054 0 16.14 0 7.226 0 0 7.226 0 16.14c0 8.914 7.226 16.14 16.14 16.14Z'
      fill='#34E9AA'
    />
    <path
      d='M17.223 26.217v-4.546a4.988 4.988 0 0 0 4.983-4.983h4.547c0 5.252-4.277 9.53-9.53 9.53ZM9.068 7.767H.908v8.673h8.16V7.767ZM19.933 7.767h-8.16v8.673h8.16V7.767Z'
      fill='#000'
    />
    <path d='M8.537 8.284H7.02V9.8h1.516V8.284Z' fill='#fff' />
    <path
      d='M7.051 9.766H5.536v1.515H7.05V9.766ZM5.403 11.29H3.887v1.515h1.516v-1.516ZM19.227 8.284H17.71V9.8h1.516V8.284Z'
      fill='#fff'
    />
    <path
      d='M17.741 9.766h-1.515v1.515h1.515V9.766ZM16.093 11.29h-1.516v1.515h1.516v-1.516Z'
      fill='#fff'
    />
    <path
      d='M18.623 11.855h13.563s3.583 0 3.583 3.96'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
    />
  </svg>
)

export default BrainOn
