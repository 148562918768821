//@ts-check
import React from 'react'
import styles from './StraightLinePreview.module.css'

function StraightLinePreview({ firstLinePoint, lineStylePreview }) {
  return (
    <div>
      {firstLinePoint && (
        <div
          className={styles.drawingLineIndicator}
          style={{
            left: firstLinePoint.x + 'px',
            top: firstLinePoint.y + 'px'
          }}
        />
      )}
      {lineStylePreview && (
        <div className={styles.lineAnimation} style={lineStylePreview} />
      )}
    </div>
  )
}

export default StraightLinePreview
