import * as React from 'react'
const ChatIcon = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      id={props.id}
      d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H6l-4 4V6c0-1.1.9-2 2-2z'
      fill='#fff'
      stroke={props.color || '#fff'}
      strokeWidth='2'
    />
    <path d='M7 10h10v1H7v-1zm0 3h8v1H7v-1z' fill={props.color || '#fff'} />
  </svg>
)
export default ChatIcon
