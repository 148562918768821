import * as React from 'react'
const Eye = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 22 17'
    fill='none'
    {...props}
  >
    <path
      fill={props.color || '#000'}
      d='M21.943 8.96c-.458-.917-.993-1.757-1.68-2.444l.993-.992a.738.738 0 0 0 0-1.07.738.738 0 0 0-1.07 0l-.992.993c-.763-.61-1.527-1.221-2.367-1.603l.61-1.222c.23-.382.077-.84-.305-1.069-.381-.229-.84-.076-1.068.306l-.688 1.298c-.992-.382-2.138-.611-3.283-.688V1.248c0-.458-.305-.764-.763-.764s-.764.306-.764.764v1.145c-1.145.077-2.29.306-3.436.764l-.687-1.298c-.23-.382-.687-.458-1.069-.306a.944.944 0 0 0-.305 1.07l.61 1.22c-.84.46-1.603.994-2.29 1.604l-.993-.992a.738.738 0 0 0-1.069 0 .738.738 0 0 0 0 1.069l.993.992C1.71 7.28 1.098 8.043.716 8.96a1.06 1.06 0 0 0 0 .687c2.978 5.879 10.08 8.17 15.958 5.268a11.728 11.728 0 0 0 5.269-5.268c.076-.23.076-.458 0-.687ZM11.33 14.61c-3.742 0-7.254-2.062-9.086-5.345 2.825-4.963 9.086-6.796 14.125-4.047 1.68.917 3.13 2.367 4.047 4.047-1.833 3.36-5.345 5.421-9.086 5.345Z'
    />
    <path
      fill={props.color || '#000'}
      d='M15.3 9.265a3.97 3.97 0 1 1-7.94 0 3.97 3.97 0 0 1 7.94 0Z'
    />
  </svg>
)
export default Eye
