//@ts-check
import React from 'react'
import { noop } from 'utils'
import Cross from 'assets/icons/Cross'
import Vector from 'assets/icons/Vector'
import Alert from 'assets/icons/Alert'
import { H4, H5 } from 'components/typography'
import styles from './Notification.module.css'

const IconByType = {
  error: null,
  success: null,
  info: null,
  warning: Alert
}

export const NotificationType = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning'
}

/**
 * @typedef NotificationProps
 * @property {string} title
 * @property {string} message
 * @property {'error' | 'success' | 'info' | 'warning' | string} type
 * @property {VoidFunction} onClose
 * @property {React.ReactNode} [children]
 */
/**
 *
 * @param {NotificationProps} props
 * @returns
 */
function Notification({
  title = '',
  message = '',
  type = 'info',
  onClose = noop,
  children
}) {
  const Icon = IconByType[type] || IconByType.warning
  return (
    <div className={styles.notification}>
      <Cross color='var(--bright)' className={styles.cross} onClick={onClose} />
      <div
        className={[
          styles.innerContainer,
          type ? styles[type] : styles.warning
        ].join(' ')}
      >
        <H4 className={styles.titleSection}>
          <Icon className={styles.icon} color='var(--bright)' />
          <span className={styles.title}>{title}</span>
        </H4>
        <Vector className={styles.vector} />
        <H5 className={styles.message}>{message}</H5>
        {children}
      </div>
    </div>
  )
}

export default Notification
