//@ts-check
import React, { forwardRef, useEffect, useRef } from 'react'
import { identity } from 'ramda'
import styles from './VideoSource.module.css'

/**
 * @typedef VideoSourceProps
 * @property {any} stream
 * @property {boolean} muted
 * @property {boolean} disabled
 * @property {'small' | 'medium' | 'large'} size
 * @property {'primary' | 'secondary' | 'tertiary'} color
 * @property {identity} onClick
 */

const VideoSource = forwardRef(
  /**
   *
   * @param {Partial<VideoSourceProps>} props
   * @param {React.Ref<any>} parentRef
   */
  (
    {
      stream = null,
      muted = false,
      disabled = false,
      size = 'medium',
      color = 'primary',
      onClick = identity
    },
    parentRef
  ) => {
    const ref = useRef(stream)
    useEffect(() => {
      if (!parentRef && stream && ref && ref.current) {
        ref.current.srcObject = stream
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stream])

    return (
      <div
        className={[
          styles.videoContainer,
          styles[color],
          styles[size],
          disabled ? styles.unfocused : ''
        ].join(' ')}
        onClick={onClick}
      >
        <video
          ref={parentRef || ref}
          className={styles.video}
          muted={muted}
          autoPlay
          disablePictureInPicture
          playsInline
        />
      </div>
    )
  }
)

export default VideoSource
