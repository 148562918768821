import * as React from 'react'
const Reload = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 512 512'
    {...props}
  >
    <path d='M142.7 35.4c-2 .8-5.2 2.5-7 3.9-2.3 1.8-16 24.4-47.7 78.8-24.4 42-45.1 78.3-45.8 80.7-2.9 8.7 2.1 19.7 10.9 24.3 3.8 1.9 5.7 1.9 95.7 1.7l91.7-.3 4.7-2.8c8.3-4.9 12.4-15 9.9-24.5-.9-3.3-3.9-7-13.7-17.4-11.5-12.3-12.5-13.6-12.2-16.8.4-4.4 3.2-6.1 16-9.4 47-12.2 97.8 3.4 128.5 39.3 21 24.6 31.3 52.4 31.3 84.7 0 34.5-12.7 65.6-37 90-49.7 50.1-131.5 50.1-180.8.1-8.5-8.6-11.3-12.1-20.1-25-13.3-19.3-34.9-25.5-55.1-15.5-16.5 8.1-26.1 26.7-23 44.5 3.8 22.3 36.4 60.7 71 83.6 19.1 12.7 46.5 24.6 68 29.6 24.5 5.7 54.6 7.1 78.3 3.7 53.2-7.6 100.3-34 135.2-75.7 53.4-63.8 64.1-154.3 27-229.4C431.1 107.7 352.6 60.8 269 64.3c-26.3 1.2-49.3 6.3-75.2 16.9-7.6 3.1-7.9 3.1-11.2 1.5-3.2-1.5-3.7-2.4-6.1-10.4-8.5-28.5-8.2-27.8-12.9-32.1-6.4-5.9-13.3-7.5-20.9-4.8z' />
  </svg>
)
export default Reload
