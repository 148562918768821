import * as React from 'react'
const Sync = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 32 30'
    {...props}
  >
    <path d='M10.3 7C8.5 8.1 7 9.4 7 10c0 1.5.9 1.2 3.7-1 3.8-3 7.9-2.6 11.6 1.2 2.8 2.8 3 3.2 1.4 3.7-1.7.6-1.7.8.3 2.6s2.1 1.8 3.5-.1c1.8-2.4 1.9-3.4.1-3.4-.8 0-1.8-.9-2.1-2.1C24.7 8.3 19.3 5 16 5c-1.4 0-4 .9-5.7 2zM4 15.9c-1.3 2.6-1.3 3.1.4 3.1.8 0 1.8.9 2.1 2.1.8 2.6 6.2 5.9 9.5 5.9 2.8 0 9-3.4 9-5 0-1.5-.9-1.2-3.7 1-3.8 3-7.9 2.6-11.6-1.2-2.8-2.8-3-3.3-1.4-3.6 1.8-.4 1.8-.5.1-2.3-2.2-2.4-3.1-2.4-4.4 0z' />
  </svg>
)
export default Sync
