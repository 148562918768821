//@ts-check
import { useState } from 'react'
import { classroomToolbarIds } from 'components/academy/classroom/Toolbar'
import { drawLine } from 'utils/canvas'

export default function useStraightLine({
  selectedTool,
  canvasRef,
  tool,
  socket,
  student,
  handleSaveContext,
  sendWhiteboardLine
}) {
  const [firstLinePoint, setFirstLinePoint] = useState(null)
  const [straightLinePoints, setStraightLinePoints] = useState([])
  const [lineStylePreview, setLineStylePreview] = useState(null)

  const isDrawingStraightLine = selectedTool === classroomToolbarIds.line

  const handleDrawStraightLine = e => {
    if (!canvasRef || !isDrawingStraightLine) return
    const { clientY, clientX } = e
    const { width, height, x, y, left, top } =
      canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    setFirstLinePoint({ x: clientX - left, y: clientY - top })

    setStraightLinePoints(prevPoints => {
      const newPoints = [...prevPoints, { x: endX, y: endY }]
      if (newPoints.length === 2) {
        drawLine(
          canvasRef,
          newPoints[0].x,
          newPoints[0].y,
          newPoints[1].x,
          newPoints[1].y,
          tool.color,
          tool.lineWidth
        )
        sendWhiteboardLine({
          socket,
          student,
          x0: newPoints[0].x / width,
          y0: newPoints[0].y / height,
          x1: newPoints[1].x / width,
          y1: newPoints[1].y / height,
          color: tool.color,
          lineWidth: tool.lineWidth,
          saveContext: true
        })
        handleSaveContext()
        setFirstLinePoint(null)
        setLineStylePreview(null)
        return []
      }
      return newPoints
    })
  }
  const handleShowStraightLinePreview = e => {
    if (straightLinePoints.length === 1) {
      const canvas = canvasRef.current
      const { x, y } = canvas.getBoundingClientRect()
      const endX = e.clientX - x
      const endY = e.clientY - y

      const dx = endX - straightLinePoints[0].x
      const dy = endY - straightLinePoints[0].y
      const distance = Math.sqrt(dx * dx + dy * dy)
      const angle = Math.atan2(dy, dx) * (180 / Math.PI)

      setLineStylePreview({
        left: `${straightLinePoints[0].x}px`,
        top: `${straightLinePoints[0].y}px`,
        width: `${distance - 2}px`,
        transform: `rotate(${angle}deg)`,
        borderStyle: [0, 90, 180, 270, 360].includes(angle) ? 'solid' : 'dashed'
      })
    }
  }

  const resetStraightLineState = () => {
    setStraightLinePoints([])
    setFirstLinePoint(null)
    setLineStylePreview(null)
  }
  return {
    isDrawingStraightLine,
    firstLinePoint,
    lineStylePreview,
    handleDrawStraightLine,
    handleShowStraightLinePreview,
    resetStraightLineState
  }
}
