import React from 'react'
import useNavigation from 'hooks/useNavigation'
import Button from 'components/buttons/Button'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import { H3 } from 'components/typography'
import styles from './Home.module.css'

const Home = () => {
  const navigate = useNavigation()
  return (
    <section className={styles.section}>
      <div className={styles.card}>
        <DarkClassfy className={styles.cardImage} />
        <H3>
          Al entrar en clase aparecerás como disponible para los alumnos que se
          conecten y podrán iniciar una clase contigo a través de videollamada.
        </H3>
        <Button label='Entrar en clase' onClick={() => navigate('academy')} />
      </div>
    </section>
  )
}
export default Home
