//@ts-check
import React, { memo, useEffect, useState } from 'react'
import FeelingGoodColored from 'assets/svg/FeelingGoodColored'
import Video from 'assets/icons/Video'
import { findBrainVideos } from 'api/brain'
import { H2 } from 'components/typography'
import Select from 'components/selects/Select'
import Button from 'components/buttons/Button'
import HangingUp from 'components/buttons/HangingUp'
import styles from './Video.module.css'

function ClassroomVideo({ learningVideo, hidden, onSelect, onHangUp }) {
  const [videos, setVideos] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    setIsFetching(true)
    findBrainVideos()
      .then(setVideos)
      .catch(console.error)
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <div className={styles.blackboard} hidden={hidden}>
      <div className={styles.innerContainer}>
        {!learningVideo && (
          <div className={styles.step1}>
            <FeelingGoodColored className={styles.svg} />
            <H2>No lo cuentes, muéstralo</H2>
            <VideoSelectorMemo
              videos={videos}
              onSelect={onSelect}
              isFetching={isFetching}
            />
          </div>
        )}
        {learningVideo && (
          <iframe
            title='Video de YouTube'
            className={styles.video}
            src={learningVideo + '?rel=0'}
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </div>

      <div className={styles.tools}>
        <HangingUp onHangUp={onHangUp} />
        {learningVideo && (
          <div
            title='Dejar de compartir'
            className={styles.right}
            onClick={() => onSelect('')}
          >
            <Video className={styles.icons} color='var(--dark-color)' />
          </div>
        )}
      </div>
    </div>
  )
}

export default ClassroomVideo

const customStyles = {
  container: styles.customSelectContainer
}
function VideoSelector({ videos, onSelect, isFetching }) {
  const [resource, setResource] = useState({ category: '', element: '' })
  return (
    <div className={styles.videoSelector}>
      <Select
        label='Asignatura'
        placeholder='Selecciona asignatura'
        options={videos.reduce(
          (acc, curr) =>
            acc.includes(curr.category) ? acc : [...acc, curr.category],
          []
        )}
        isLoading={isFetching}
        value={resource.category}
        onChange={e => setResource({ category: e.target.value, element: '' })}
        customStyles={customStyles}
        transformList
      />
      <Select
        label='Vídeo'
        placeholder='Selecciona vídeo'
        labelKey='name'
        valueKey='url'
        menuPlacement='top'
        options={videos.filter(item => item.category === resource.category)}
        value={resource.element}
        onChange={e => setResource({ ...resource, element: e.target.value })}
        customStyles={customStyles}
        disabled={!resource.category}
      />
      <Button
        label='Cargar vídeo'
        size='small'
        type='accent-primary'
        onClick={() => onSelect(resource.element)}
        disabled={!resource.element}
      />
    </div>
  )
}

const VideoSelectorMemo = memo(VideoSelector)
