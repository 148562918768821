import React, { useEffect } from 'react'
import { BASE_URL } from 'api'
import { useNotificationActions } from 'context/NotificationProvider'
import useLocalStorage from 'hooks/useLocalStorage'
import styles from './VersionChecker.module.css'
import packageJson from '../../../package.json'

const currentVersion = packageJson.version

function VersionChecker() {
  const { setInfoMessage } = useNotificationActions()
  const { item: storedVersion, saveItem } = useLocalStorage('version', '')

  useEffect(() => {
    const eventSource = new EventSource(BASE_URL + 'health/teacher-version')

    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data)
      const info = {
        title: data.push ? '¡Aviso del desarrollador! 🚧' : '¡Importante!',
        message: `Nueva versión (${data.teacherVersion}) disponible. Por favor, refresca la página cuando sea posible.`,
        ms: 1000 * 60 * 60 * 24,
        children: data.teacherUrl && (
          <div className={styles.links}>
            <div
              className={styles.link}
              onClick={() => {
                saveItem(data.teacherVersion)
                window.open(data.teacherUrl, '_blank', 'noopener,noreferrer')
                window.location.reload(true)
              }}
            >
              Revisar y refrescar ahora
            </div>
            <div
              className={styles.link}
              onClick={() => {
                saveItem(data.teacherVersion)
                window.open(data.teacherUrl, '_blank', 'noopener,noreferrer')
              }}
            >
              Solo revisar
            </div>
          </div>
        )
      }
      if (data.push) return setInfoMessage(info)
      const list = [data.teacherVersion, storedVersion]
      if (!list.every(v => v === list[0])) setInfoMessage(info)
    }

    eventSource.onerror = function (error) {
      console.error('SSE error:', error)
      eventSource.close()
    }
    const handleBeforeUnload = () => eventSource.close()

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      eventSource.close()
    }
  }, [saveItem, setInfoMessage, storedVersion])

  return null
}

export default VersionChecker
