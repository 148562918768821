//@ts-check
import React, { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { uploadPhoto } from 'api/photos'
import { useNotificationActions } from 'context/NotificationProvider'
import Button from 'components/buttons/Button'
import { H1 } from 'components/typography'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import ImageIcon from 'assets/icons/Image'
import styles from './UploadPhoto.module.css'

function UploadPhoto() {
  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [saving, setSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  const inputRef = useRef(null)
  const { studentId } = useParams()

  const handleFileChange = event => {
    const file = event.target?.files[0]
    if (!file) return
    setSelectedFile(file)
    setPreview(URL.createObjectURL(file))
  }

  const handleSavingPhoto = useCallback(() => {
    setSaving(true)
    uploadPhoto({
      photo: selectedFile,
      studentId: studentId
    })
      .then(() => {
        setSuccessMessage({
          title: 'Imagen subida',
          message: 'Imagen subida correctamente'
        })
        setSelectedFile(null)
        setPreview(null)
      })
      .catch(e => {
        setErrorMessage({})
        console.error('Error saving photo: ', e)
      })
      .finally(() => {
        setSaving(false)
      })
  }, [selectedFile, studentId, setSuccessMessage, setErrorMessage])

  return (
    <section className={styles.section}>
      <div className={styles.containerLogo}>
        <DarkClassfy className={styles.classfyLogo} />
      </div>
      <H1 className={styles.title}>Sube tus fotos 😎 </H1>

      <div className={styles.card}>
        <div className={styles.imgContainer}>
          {preview ? (
            <img src={preview} alt='Imagen a subir' className={styles.image} />
          ) : (
            <ImageIcon color='var(--dadada)' className={styles.defaultImage} />
          )}
        </div>
        <div className={styles.cardActions}>
          <Button
            size='small'
            type='secondary'
            onClick={() => inputRef.current.click()}
          >
            Seleccionar
            <input
              ref={inputRef}
              type='file'
              accept='image/*'
              name='photo'
              onChange={handleFileChange}
              capture
              hidden
            />
          </Button>
          <Button
            label='Subir'
            size='small'
            type='primary'
            disabled={!selectedFile || saving}
            loading={saving}
            onClick={handleSavingPhoto}
          />
        </div>
      </div>
    </section>
  )
}

export default UploadPhoto
