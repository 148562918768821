// @ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useFormManager from 'hooks/useFormManager'
import { H2, H3, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Modal from 'components/modals/Modal'
import TextInput from 'components/inputs/TextInput'
import Select from 'components/selects/Select'
import { uploadTeacherProfilePicture } from 'api/account'
import { noop } from 'utils'
import defaultImg from 'assets/images/robot.png'
import Pencil from 'assets/icons/Pencil'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import styles from './PersonalData.module.css'

const REQUIRED_FIELDS = ['name', 'email']

function PersonalData() {
  const [isValidImage, setIsValidImage] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [isSavingPhoto, setIsSavingPhoto] = useState(false)
  const { teacher, setTeacher } = useAuth()
  const imageUrl = teacher.profileUrl

  const { getErrorPropsByRequiredField, form } = useFormManager(
    REQUIRED_FIELDS,
    teacher
  )

  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  const inputRef = useRef(null)

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
    setPreview(null)
  }

  const handleFileChange = event => {
    const file = event.target?.files[0]
    if (!file) return
    setSelectedFile(file)
    setPreview(URL.createObjectURL(file))
  }

  const handleSavingPhoto = useCallback(() => {
    setIsSavingPhoto(true)
    uploadTeacherProfilePicture({
      photo: selectedFile
    })
      .then(updatedPhotoProfile => {
        setSuccessMessage({
          title: 'Imagen subida',
          message: 'Imagen subida correctamente'
        })
        setTeacher(updatedPhotoProfile)
        setSelectedFile(null)
        closeModal()
      })
      .catch(error => {
        setErrorMessage({ message: error.message })
        console.error('Error saving photo: ', error)
      })
      .finally(() => {
        setIsSavingPhoto(false)
      })
  }, [selectedFile, setSuccessMessage, setTeacher, setErrorMessage])

  useEffect(() => {
    const img = new Image()
    img.onload = function () {
      setIsValidImage(true)
    }
    img.onerror = function () {
      setIsValidImage(false)
    }
    img.src = imageUrl
  }, [imageUrl])

  return (
    <section className={styles.section}>
      <H2>Datos personales 🤙</H2>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.containerProfileImg}>
            {isValidImage ? (
              <img
                src={imageUrl}
                alt='Foto de perfil'
                className={styles.profileImg}
              />
            ) : (
              <img
                src={defaultImg}
                alt='Foto por defecto'
                className={styles.defaultProfileImage}
              />
            )}
            <div className={styles.pencilIconContainer} onClick={openModal}>
              <Pencil
                className={styles.pencilIcon}
                color='var(--sandground)'
                stroke='var(--sandground)'
              />
            </div>
          </div>
          <div className={styles.personalInfoContainer}>
            <TextInput
              name='name'
              placeholder='Nombre completo'
              value={form.name}
              onChange={noop}
              {...getErrorPropsByRequiredField('name')}
            />
            <TextInput
              name='email'
              placeholder='Correo electrónico'
              value={form.email}
              onChange={noop}
              {...getErrorPropsByRequiredField('email')}
            />
            <Select
              name='type'
              placeholder='Tipo'
              value={form.type}
              options={[form.type]}
              transformList
              onChange={noop}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <Modal showActions={false} onCancel={closeModal} showCloseIcon>
          <div className={styles.modalContainer}>
            <H3>Imagen de perfil 🤳</H3>

            <Paragraph>
              !Dale a tu perfil un toque personal! Agrega tu foto de perfil con
              tan solo un click.
            </Paragraph>

            <div className={styles.profileImageContainer}>
              {preview || isValidImage ? (
                <div className={styles.profileImageWrapper}>
                  <img
                    src={preview || imageUrl}
                    alt='Foto de perfil'
                    className={styles.profileImg}
                  />
                </div>
              ) : (
                <div className={styles.defaultImageWrapper}>
                  <img
                    src={defaultImg}
                    alt='Foto por defecto'
                    className={styles.defaultProfileImage}
                  />
                </div>
              )}
            </div>

            <div className={styles.modalActions}>
              <Button
                size='tiny'
                type='secondary'
                onClick={() => inputRef.current.click()}
              >
                Seleccionar
                <input
                  ref={inputRef}
                  type='file'
                  accept='image/*'
                  name='photo'
                  onChange={handleFileChange}
                  capture
                  hidden
                />
              </Button>
              <Button
                label='Subir'
                size='tiny'
                type='primary'
                disabled={!selectedFile || isSavingPhoto}
                loading={isSavingPhoto}
                onClick={handleSavingPhoto}
              />
            </div>
          </div>
        </Modal>
      )}
    </section>
  )
}

export default PersonalData
