//@ts-check
import React from 'react'
import { Navigate } from 'react-router-dom'
import config from 'config/index'
import useLocalStorage from 'hooks/useLocalStorage'
import Classroom from 'components/academy/classroom/Classroom'
import styles from './Academy.module.css'

function Academy() {
  const { item: isVideoTourViewed } = useLocalStorage(
    config.isVideoTourViewedKey,
    false
  )
  const { item: hasMediaPermissions } = useLocalStorage(
    config.hasMediaPermissionsKey,
    false
  )

  if (!isVideoTourViewed || !hasMediaPermissions)
    return <Navigate to='/academy/video-tour' />

  return (
    <section className={styles.section}>
      <Classroom />
    </section>
  )
}

export default Academy
