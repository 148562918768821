export const drawLine = (canvas, x0, y0, x1, y1, color, lineWidth) => {
  if (!canvas || !canvas.current) return false
  const context = canvas.current.getContext('2d', {
    willReadFrequently: true
  })
  context.beginPath()
  context.moveTo(x0, y0)
  context.lineTo(x1, y1)
  if (color === 'transparent')
    context.globalCompositeOperation = 'destination-out'
  else {
    context.globalCompositeOperation = 'source-over'
    context.strokeStyle = color
  }
  context.lineWidth = lineWidth
  context.stroke()
  context.closePath()
  return true
}

export const clear = canvas => {
  if (!canvas || !canvas.current) return false
  canvas.current
    .getContext('2d', {
      willReadFrequently: true
    })
    .clearRect(0, 0, canvas.current.width, canvas.current.height)
}

export const getBackgroundData = canvas => {
  const { width, height } = canvas?.current || {}
  const numberOfLines = Math.floor(height / 20)
  const numberOfColumns = Math.floor(width / 20)
  const lineSpacing = height / numberOfLines
  const lineSpacingColumns = width / numberOfColumns
  const verticalLinePosition = width / 26
  return {
    width,
    height,
    numberOfLines,
    numberOfColumns,
    lineSpacing,
    lineSpacingColumns,
    verticalLinePosition
  }
}

export const svgByBgdType = {
  emptyNotebook: () => '',
  gridNotebook: canvas => {
    const { width, height, lineSpacing, lineSpacingColumns } =
      getBackgroundData(canvas)
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      ${Array.from({ length: Math.ceil(height / lineSpacing) })
        .map(
          (_, rowIndex) => `
        <path d="M0 ${
          rowIndex * lineSpacing
        } H${width}" fill="none" stroke="#ccc" stroke-width="1"/>
      `
        )
        .join('')}
      ${Array.from({ length: Math.ceil(width / lineSpacingColumns) })
        .map(
          (_, colIndex) => `
        <path d="M${
          colIndex * lineSpacingColumns
        } 0 V${height}" fill="none" stroke="#ccc" stroke-width="1"/>
      `
        )
        .join('')}
    </svg>
    `
  },
  linedNotebook: canvas => {
    const { width, height, lineSpacing } = getBackgroundData(canvas)
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      ${Array.from({ length: Math.ceil(height / lineSpacing) })
        .map(
          (_, rowIndex) => `
        <line x1="0" y1="${lineSpacing * rowIndex}" x2="${width}" y2="${
            lineSpacing * rowIndex
          }" stroke="#ccc" stroke-width="1"/>
      `
        )
        .join('')}
    </svg>
    `
  },
  linedNotebookVerticalLine: canvas => {
    const { width, height, lineSpacing, verticalLinePosition } =
      getBackgroundData(canvas)
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <defs>
        <pattern id="grid" width="${width}" height="${lineSpacing}" patternUnits="userSpaceOnUse">
          <path d="M0 ${
            lineSpacing - 1
          } H${width}" stroke="#ccc" stroke-width="1"/>
        </pattern>
        <pattern id="verticalLine" width="${width}" height="${height}" patternUnits="userSpaceOnUse">
          <rect width="${width}" height="${height}" fill="none" />
          <path d="M${verticalLinePosition} 0 V${height}" stroke="red" stroke-width="1"/>
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#grid)" />
      <rect width="100%" height="100%" fill="url(#verticalLine)" />
    </svg>
    `
  },
  dottedNotebook: canvas => {
    const { width, height, lineSpacing, lineSpacingColumns } =
      getBackgroundData(canvas)
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      ${Array.from({ length: Math.ceil(height / lineSpacing) }, (_, rowIndex) =>
        Array.from(
          { length: Math.ceil(width / lineSpacingColumns) },
          (_, colIndex) =>
            `<circle cx="${colIndex * lineSpacingColumns}" cy="${
              rowIndex * lineSpacing
            }" r="1" fill="#ccc"/>`
        ).join('')
      ).join('')}
    </svg>
    `
  }
}

export const handleDownload = (canvas, backgroundType = '') => {
  const fauxCanvas = document.createElement('canvas')
  fauxCanvas.width = canvas.current.width
  fauxCanvas.height = canvas.current.height
  const fauxContext = fauxCanvas.getContext('2d', { willReadFrequently: true })
  fauxContext.fillStyle = '#fff'
  fauxContext.fillRect(0, 0, fauxCanvas.width, fauxCanvas.height)

  switch (backgroundType) {
    case 'gridNotebook':
    case 'linedNotebook':
    case 'linedNotebookVerticalLine':
    case 'dottedNotebook': {
      const svg = svgByBgdType[backgroundType](canvas)
      const img = new Image()
      const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
      const url = URL.createObjectURL(svgBlob)

      img.onload = () => {
        const pattern = fauxContext.createPattern(img, null)
        fauxContext.fillStyle = pattern
        commonBuilder(fauxContext, canvas, fauxCanvas)
        URL.revokeObjectURL(url)
      }
      img.src = url
      break
    }

    default: {
      commonBuilder(fauxContext, canvas, fauxCanvas)
      break
    }
  }
}
function commonBuilder(fauxContext, canvas, fauxCanvas) {
  fauxContext.fillRect(0, 0, canvas.current.width, canvas.current.height)
  fauxContext.drawImage(canvas.current, 0, 0)
  const data = fauxCanvas.toDataURL('image/jpeg')
  const fauxLink = document.createElement('a')
  fauxLink.href = data
  fauxLink.download = 'board.jpg'
  document.body.appendChild(fauxLink)
  fauxLink.click()
  document.body.removeChild(fauxLink)
}
