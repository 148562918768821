// @ts-check
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Outlet } from 'react-router-dom'
import { fetchMe } from 'api/auth'
import useNavigation from 'hooks/useNavigation'
import CONFIG from 'config'

const AuthContext = createContext(null)

function makePictureUrl(url) {
  if (!url) return ''
  return url.split('?v')[0] + `?v=${Date.now()}`
}

function makeCustomAttrs(teacher) {
  let name = teacher?.name?.split(' ')?.[0]
  teacher.shortName = name
    ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    : '???'
  teacher.profileUrl = makePictureUrl(teacher.profileUrl)
  teacher.profileThumbnailUrl = makePictureUrl(teacher.profileThumbnailUrl)
  return teacher
}

const JWT_ERROR = 'jwtError'

function AuthProvider() {
  const [isFetchingMe, setIsFetchingMe] = useState(true)
  const [teacher, setTeacher] = useState({})
  const navigate = useNavigation()

  const isAuthenticated = !!localStorage.getItem(CONFIG.accessTokenKey)

  const handleLogout = useCallback(() => {
    localStorage.removeItem(CONFIG.accessTokenKey)
    navigate('/login')
  }, [navigate])

  const handleFetchMe = useCallback(() => {
    setIsFetchingMe(true)
    fetchMe({ full: true })
      .then(teacher => {
        if (!teacher.active) return handleLogout()
        makeCustomAttrs(teacher)
        return teacher
      })
      .then(setTeacher)
      .catch(e => {
        console.error('Error fetching me: ', e)
        if (e.code === JWT_ERROR && isAuthenticated) handleLogout()
      })
      .finally(() => setIsFetchingMe(false))
  }, [handleLogout, isAuthenticated])

  const saveAuthentication = useCallback(({ token, teacher = {} }) => {
    localStorage.setItem(CONFIG.accessTokenKey, token)
    setTeacher(makeCustomAttrs(teacher))
  }, [])

  useEffect(() => {
    handleFetchMe()
  }, [handleFetchMe])
  if (isFetchingMe) return null
  return (
    <AuthContext.Provider
      // @ts-ignore
      value={{
        teacher,
        isFetchingMe,
        isAuthenticated,
        handleFetchMe,
        setTeacher: teacher =>
          setTeacher(currentTeacher =>
            makeCustomAttrs({ ...currentTeacher, ...teacher })
          ),
        saveAuthentication,
        handleLogout
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthProvider

/**
 *
 * @returns {{
 * teacher: object
 * isFetchingMe: boolean
 * isAuthenticated: boolean
 * handleFetchMe: ()=> Promise<object>
 * setTeacher: (teacher: object)=> void
 * saveAuthentication: ({token, teacher}: {token:string, teacher:object})=> void
 * handleLogout: ()=> void
 * }}
 */
// @ts-ignore
export const useAuth = () => useContext(AuthContext)
