import CONFIG from 'config'
export const getAuthenticationToken = () => {
  return localStorage.getItem(CONFIG.accessTokenKey)
}
export const saveAuthentication = ({ token, data }) => {
  localStorage.setItem('online_access_token', token)
  localStorage.setItem('online_access_details', JSON.stringify(data))
}

export const clearAuthentication = () => {
  localStorage.removeItem('online_access_token')
  localStorage.removeItem('online_access_details')
}

export const isAuthenticated = () => {
  return localStorage.getItem('online_access_token')
}

export const getAuthenticationDetails = () => {
  const details = localStorage.getItem('online_access_details')
  return details ? JSON.parse(details) : null
}

/**
 *
 * @param {{target:{name: string | array, value: any}}} e
 * @returns {{path: string[], value: any}}
 */
export function makeCustomChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, value: e.target.value }
  return { path: e.target.name.split('.'), value: e.target.value }
}
/**
 *
 * @typedef PhoneNumberEvent
 * @property {string} fullPhoneNumber
 * @property {string} cleanedPhone
 * @property {string} countryCode
 * @property {string} dialCode
 * @property {string} name country name
 */

/**
 *
 * @param {string} name name in form, can include dots
 * @param {string} value phone only without dialCode and especial chars
 * @param {import('react-phone-input-2').CountryData} countryData
 * @param {React.ChangeEvent<HTMLInputElement>} e
 * @param {string} fullPhoneNumber
 * @returns {{ target:{name: string, value: PhoneNumberEvent}}}
 */
export function makeCustomChangeEventPhoneNumber(
  name = '',
  value,
  countryData,
  e,
  fullPhoneNumber
) {
  const { format, ...details } = countryData //exclude format

  return {
    target: {
      name,
      value: {
        fullPhoneNumber,
        cleanedPhone: fullPhoneNumber
          .replace(/\D/g, '')
          .slice(details.dialCode.length),
        ...details
      }
    }
  }
}

/**
 *
 * @param {{target:{name: string | array, checked: boolean}}} e
 * @returns {{path: string[], checked: any}}
 */
export function makeCustomCheckChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, checked: e.target.checked }
  return { path: e.target.name.split('.'), checked: e.target.checked }
}

export const noop = () => {}
