import { POST } from './index'
/**
 *
 * @param {{photo: File, studentId: string}} data
 */
export const uploadPhoto = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return POST({ url: 'upload-photo', data: formData, useAcceptAPP_JSON: true })
}
