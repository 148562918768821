//@ts-check
import React, { useState } from 'react'
import styles from './Table.module.css'

const Table = ({ data, columns }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' })

  const sortedData = () => {
    if (sortConfig.key !== null) {
      return [...data].sort((a, b) => {
        const aValue = a[sortConfig.key]
        const bValue = b[sortConfig.key]
        return sortConfig.direction === 'asc'
          ? aValue?.localeCompare && aValue.localeCompare(bValue)
          : bValue?.localeCompare && bValue.localeCompare(aValue)
      })
    }
    return [...data]
  }

  const requestSort = key => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          {columns.map(column => (
            <th key={column.key} onClick={() => requestSort(column.key)}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData().map((row, index) => (
          <tr key={index}>
            {columns.map(column => (
              <td key={column.key}>{row[column.key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
