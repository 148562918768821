//@ts-check
import { useCallback, useEffect, useRef, useState } from 'react'
import { assoc, isEmpty, pick, pipe, uniq, without } from 'ramda'
import lifecycle from 'page-lifecycle'
import { sendActiveStudent, sendChatMessage } from 'api/sockets'
import { toTimeString } from 'utils/date'

const MAX_STUDENTS_IN_A_COLUMN = 4
const PAGE_STATES = ['hidden', 'passive']

const initialState = {
  activeStudent: '',
  selectedStudentChat: '',
  studentLightbulbs: [],
  lightbulbIdsByStudent: {},
  studentsReconnecting: [],
  newPageState: '',
  messages: {},
  isChatModalOpen: false
}

export default function useClassroom({ socket, students, remoteStreams }) {
  const [state, setState] = useState(initialState)
  const timeByStudent = useRef({})

  const {
    activeStudent,
    selectedStudentChat,
    studentLightbulbs,
    lightbulbIdsByStudent,
    studentsReconnecting,
    newPageState,
    messages,
    isChatModalOpen
  } = state

  const handleSetTimerByStudent = useCallback((id, time) => {
    timeByStudent.current = { ...timeByStudent.current, [id]: time }
  }, [])

  const getStudentStream = studentId =>
    (remoteStreams.find(stream => stream._id === studentId) || {})?.stream

  const changeActiveStudent = useCallback(
    studentId => () => {
      const student = activeStudent === studentId ? '' : studentId
      sendActiveStudent({ socket, student })
        .then(() => {
          setState(
            pipe(
              assoc('activeStudent', student),
              assoc('selectedStudentChat', student)
            )
          )
          if (student)
            setState(state => ({
              ...state,
              studentLightbulbs: state.studentLightbulbs.filter(
                light => light !== student
              )
            }))
        })
        .catch(e => {
          console.error('Error changing active student: ', e)
        })
    },
    [activeStudent, socket]
  )

  const openChatModal = studentId =>
    setState(
      pipe(
        assoc('isChatModalOpen', true),
        assoc('selectedStudentChat', studentId)
      )
    )

  const closeChatModal = () =>
    setState(
      pipe(assoc('isChatModalOpen', false), assoc('selectedStudentChat', ''))
    )

  const handleSendMessage = message => {
    if (message.trim()) {
      sendChatMessage({ socket, student: selectedStudentChat, message }).catch(
        _ => null
      )
      setState(prevState => ({
        ...prevState,
        messages: {
          ...prevState.messages,
          [selectedStudentChat]: [
            ...(prevState.messages[selectedStudentChat] || []),
            {
              text: message,
              timestamp: toTimeString(new Date()),
              type: 'sent'
            }
          ]
        }
      }))
    }
  }
  const handleCleanNewMessage = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        messages: {
          ...prevState.messages,
          [selectedStudentChat]: (
            prevState.messages[selectedStudentChat] || []
          ).map(m => {
            delete m.isNew
            return m
          })
        }
      })),
    [selectedStudentChat]
  )
  useEffect(() => {
    if (!socket) return
    socket.on('classroom:students:chat-message', ({ message, studentId }) => {
      setState(prevState => ({
        ...prevState,
        messages: {
          ...prevState.messages,
          [studentId]: [
            ...(prevState.messages[studentId] || []),
            {
              text: message,
              timestamp: toTimeString(new Date()),
              type: 'received',
              isNew: true
            }
          ]
        }
      }))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:students:chat-message')
    }
  }, [socket])

  useEffect(() => {
    if (!activeStudent) return
    if (students.find(student => student._id === activeStudent)) return
    setState(assoc('activeStudent', ''))
    closeChatModal()
  }, [activeStudent, students])

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:students:lightbulb', ({ studentId, id, status }) => {
      if (status === 'OFF')
        setState(state => {
          const { [studentId]: _, ...restLightbulbIdsByStudent } =
            state.lightbulbIdsByStudent

          return {
            ...state,
            lightbulbIdsByStudent: restLightbulbIdsByStudent,
            studentLightbulbs: state.studentLightbulbs.filter(
              light => light !== studentId
            )
          }
        })

      if (status === 'ON')
        setState(state => ({
          ...state,
          lightbulbIdsByStudent: {
            ...state.lightbulbIdsByStudent,
            [studentId]: id
          },
          studentLightbulbs: [
            ...state.studentLightbulbs.filter(light => light !== studentId),
            studentId
          ]
        }))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:students:lightbulb')
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:students:reconnecting', studentId => {
      setState(state => ({
        ...state,
        studentsReconnecting: uniq(state.studentsReconnecting.concat(studentId))
      }))
      if (studentId === activeStudent) changeActiveStudent('')()
    })
    return () => {
      if (!socket) return
      socket.off('classroom:students:reconnecting')
    }
  }, [activeStudent, changeActiveStudent, socket])

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:students:reconnected', studentId => {
      setTimeout(() => {
        setState(state => ({
          ...state,
          studentsReconnecting: without(state.studentsReconnecting, [studentId])
        }))
      }, 1500)
    })
    return () => {
      if (!socket) return
      socket.off('classroom:students:reconnected')
    }
  }, [socket])

  useEffect(() => {
    if (studentsReconnecting.length) {
      setState(state => ({
        ...state,
        studentsReconnecting: studentsReconnecting.filter(studentId => {
          return students.find(({ _id }) => _id === studentId)
        })
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students])

  useEffect(() => {
    if (studentLightbulbs.length) {
      setState(state => ({
        ...state,
        studentLightbulbs: studentLightbulbs.filter(studentId => {
          return students.find(({ _id }) => _id === studentId)
        })
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students])

  useEffect(() => {
    if (messages && !isEmpty(messages)) {
      setState(state => {
        const studentIds = students.map(s => s._id)
        return {
          ...state,
          messages: pick(studentIds, state.messages)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students])

  useEffect(() => {
    if (!timeByStudent.current || !students) return
    let availableTimes = {}
    Object.keys(timeByStudent.current).forEach(id => {
      if (students.findIndex(s => s._id === id) >= 0) {
        availableTimes[id] = timeByStudent.current[id]
      }
    })
    timeByStudent.current = availableTimes
  }, [students, timeByStudent])

  useEffect(() => {
    const listener = function (event) {
      setState(assoc('newPageState', event.newState))
    }
    lifecycle.addEventListener('statechange', listener)

    return () => {
      lifecycle.removeEventListener('statechange', listener)
    }
  }, [])

  useEffect(() => {
    if (PAGE_STATES.includes(newPageState) && studentLightbulbs.length > 0)
      document.title = `(${studentLightbulbs.length}) Profesores`
    else document.title = 'Profesores'
  }, [newPageState, studentLightbulbs.length])

  return {
    activeStudent,
    selectedStudentChat,
    studentLightbulbs,
    lightbulbIdsByStudent,
    studentsReconnecting,
    newPageState,
    timeByStudent,
    messages,
    isChatModalOpen,
    isDoubleColumn: students.length > MAX_STUDENTS_IN_A_COLUMN,
    handleSetTimerByStudent,
    getStudentStream,
    changeActiveStudent,
    clearStudentsReconnecting: () =>
      setState(state => ({
        ...state,
        studentsReconnecting: []
      })),
    openChatModal,
    closeChatModal,
    handleSendMessage,
    handleCleanNewMessage
  }
}
