//@ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { noop } from 'utils'
import { useAuth } from 'context/AuthProvider'
import config from 'config'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import fakeImg from 'assets/images/test.png'
import useNavigation from 'hooks/useNavigation'
import ChevronBottom from 'assets/icons/ChevronBottom'
import { H5 } from 'components/typography'
import styles from './Header.module.css'

export const HeaderProfile = ({
  teacher,
  className = '',
  useGreeting = true,
  onClick = noop
}) => {
  const imageUrl = teacher.profileThumbnailUrl

  const [isValidImage, setIsValidImage] = useState(false)
  useEffect(() => {
    const img = new Image()
    img.onload = function () {
      setIsValidImage(true)
    }
    img.onerror = function () {
      setIsValidImage(false)
    }
    img.src = imageUrl
  }, [imageUrl])
  return (
    <div className={className || styles.userInfo} onClick={onClick}>
      {useGreeting ? (
        <>
          <p>Hola, {teacher?.shortName}</p>
          <img src={isValidImage ? imageUrl : fakeImg} alt='profile picture' />
        </>
      ) : (
        <>
          <img src={isValidImage ? imageUrl : fakeImg} alt='profile picture' />
          <p>{teacher?.shortName}</p>
        </>
      )}
    </div>
  )
}

function Header() {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const { teacher } = useAuth()
  return (
    <header className={styles.header}>
      <Link to='/'>
        <DarkClassfy className={styles.classfyLogo} />
      </Link>
      {config.environmentTag && <H5>{config.environmentTag}</H5>}
      <div className={styles.right} onClick={() => setShowDropdownMenu(true)}>
        <HeaderProfile className={styles.userInfo} teacher={teacher} />
        <div className={styles.menuWrapper}>
          <ChevronBottom className={styles.chevronBottom} />
          {showDropdownMenu && (
            <DropdownMenu
              onClose={e => {
                e?.stopPropagation()
                setShowDropdownMenu(false)
              }}
            />
          )}
        </div>
      </div>{' '}
    </header>
  )
}

export default Header

function DropdownMenu({ onClose }) {
  const { handleLogout } = useAuth()
  const navigate = useNavigation()
  const dropdownRef = useRef(null)

  const handleClickOutside = useCallback(
    e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <ul>
        {/* <li
          onClick={e => {
            navigate('/account')
            onClose(e)
          }}
        >
          Cuenta
        </li> */}
        <li
          onClick={e => {
            handleLogout()
            onClose(e)
          }}
        >
          Cerrar sesión
        </li>
      </ul>
    </div>
  )
}
