import React from 'react'
import styles from './Tooltip.module.css'

const Tooltip = ({ label, children }) => {
  return (
    <div className={styles.tooltip}>
      {children}
      <div className={styles['tooltip-text']}>{label}</div>
    </div>
  )
}

export default Tooltip
