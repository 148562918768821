import * as React from 'react'
const Phone = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 46 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      d='m7.905 28.588 5.37-.044c1.08.01 2.006-.349 2.679-1.034.668-.68 1.016-1.616 1.01-2.706l-.025-2.796.301-.102c.245-.085.484-.164.704-.216 2.314-.538 4.785-.672 7.552-.417 1.241.134 2.204.329 3.054.619-.016.686-.006 1.358.008 1.998.005.268.007.548.01.819.01 1.118.374 2.062 1.052 2.728.682.671 1.632 1.02 2.746 1.005l2.666-.023c.302-.003.593-.003.897.001h.014a35.3 35.3 0 0 0 1.826-.025c1.053-.02 1.961-.389 2.628-1.067.661-.673 1.019-1.596 1.034-2.668l-.029-3.36a5.358 5.358 0 0 0-1.33-3.57c-1.048-1.208-2.452-2.181-4.413-3.058-2.98-1.345-6.195-2.122-10.12-2.447-4.741-.38-9.384.226-13.795 1.797h-.005c-1.693.622-3.868 1.543-5.672 3.155l-.02.02a9.403 9.403 0 0 0-.315.303c-1.214 1.235-1.766 2.652-1.683 4.329.002.03.002.065.01.108.047.493.047 1.003.04 1.542l-.001.124c-.007.503-.004.91.006 1.277.032 1.1.408 2.033 1.09 2.699.683.67 1.62 1.019 2.71 1.01l.001-.001Zm30.163-9.137c.472.553.694 1.15.7 1.877l.029 3.324c-.004.358-.099.645-.275.825-.215.218-.555.264-.8.264h-.028c-.487.023-.978.022-1.497.022h-1.175l-2.675.022c-.28.003-.659-.039-.872-.25-.214-.21-.263-.588-.265-.867-.001-.14-.002-.28-.006-.422-.003-.138-.005-.277-.006-.417l-.001-.19c-.011-.757-.02-1.477.015-2.208l-.001-.076c-.01-1.168-.819-1.565-1.084-1.696-.02-.011-.036-.018-.054-.026l-.032-.014c-1.245-.499-2.564-.803-4.278-.984h-.004c-3.05-.292-5.797-.14-8.408.465-.29.063-.558.153-.817.244-.047.016-.096.034-.146.048h-.004a7.124 7.124 0 0 1-.704.216l-.063.019c-.02.007-.043.012-.063.018-.3.1-.555.256-.759.463-.344.35-.516.807-.509 1.36l.03 3.346c.001.261-.04.614-.255.832-.22.223-.582.246-.783.241h-.009l-5.399.052c-.775.006-1.114-.34-1.137-1.154v-.01a22.067 22.067 0 0 1-.01-1.159l.001-.105c.004-.596.008-1.21-.054-1.847-.04-.915.247-1.63.927-2.322.063-.064.139-.132.215-.2 1.479-1.323 3.344-2.106 4.808-2.64 4.051-1.447 8.32-2.004 12.687-1.652 3.615.303 6.555 1.01 9.259 2.223 1.596.715 2.714 1.47 3.502 2.378Z'
    />
  </svg>
)
export default Phone
