import * as React from 'react'

const StraightLine = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 24 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      fillRule='evenodd'
      d='M7.058 2.971C6.54 1.657 5.282.73 3.812.73c-1.933 0-3.5 1.604-3.5 3.583 0 1.978 1.567 3.582 3.5 3.582 1.696 0 3.11-1.235 3.431-2.876h14.068c.553 0 1-.458 1-1.023s-.447-1.024-1-1.024H7.058Zm-3.246-.195c-.829 0-1.5.688-1.5 1.536 0 .848.671 1.535 1.5 1.535.828 0 1.5-.687 1.5-1.535 0-.848-.672-1.536-1.5-1.536Z'
      clipRule='evenodd'
    />
  </svg>
)
export default StraightLine
