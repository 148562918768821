//@ts-check
import React from 'react'
import FeelingGoodColored from 'assets/svg/FeelingGoodColored'
import { H3, Paragraph } from 'components/typography'
import styles from './WaitingRoom.module.css'

function WaitingRoom() {
  return (
    <div className={styles.container}>
      <div className={styles.images}>
        <FeelingGoodColored
          className={styles.mainSvg}
          color='var(--sandground)'
        />
      </div>
      <H3 className={styles.sandground}>Waiting Room</H3>
      <H3>Sin alumno seleccionado</H3>
      <Paragraph type='body1Bold'>
        Presta atención a las bombillas de los alumnos 😝
      </Paragraph>
    </div>
  )
}

export default WaitingRoom
