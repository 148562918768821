import * as React from 'react'

const Learning = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 33 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.14 32.28c8.914 0 16.14-7.226 16.14-16.14C32.28 7.226 25.054 0 16.14 0 7.226 0 0 7.226 0 16.14c0 8.914 7.226 16.14 16.14 16.14Z'
      fill='#8080F8'
    />
    <path
      d='M24.976 20.212v3.609c-2.21 0-4.003 1.777-4.003 3.956h-3.652c0-4.174 3.434-7.565 7.655-7.565ZM3.061 19.264l1.558-1.558a2.424 2.424 0 0 0 3.421 0l1.558 1.558a4.628 4.628 0 0 1-6.537 0ZM17.998 19.044l-1.559-1.559a2.424 2.424 0 0 1-3.42 0l-1.559 1.559a4.628 4.628 0 0 0 6.538 0Z'
      fill='#000'
    />
  </svg>
)

export default Learning
