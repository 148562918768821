//@ts-check
import { useEffect, useState } from 'react'
import { getBackgroundData, svgByBgdType } from 'utils/canvas'

export default function useBackground({
  student,
  socket,
  canvas,
  sendWhiteboardType
}) {
  const [backgroundType, setBackgroundType] = useState('')

  const getStyle = () => {
    if (!backgroundType) return {}
    const svg = svgByBgdType[backgroundType]?.(canvas)
    const svgUrl = `data:image/svg+xml;base64,${btoa(svg)}`
    return {
      backgroundImage: `url(${svgUrl})`
    }
  }

  useEffect(() => {
    if (!socket || !canvas || !canvas.current || !backgroundType) return
    const { numberOfLines, numberOfColumns } = getBackgroundData(canvas)
    sendWhiteboardType({
      socket,
      student,
      type: backgroundType,
      numberOfLines,
      numberOfColumns
    })
  }, [backgroundType, canvas, socket, student, sendWhiteboardType])

  return {
    backgroundType,
    bgdStyle: getStyle(),
    setBackgroundType
  }
}
