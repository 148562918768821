const textByEnvironment = {
  development: 'Entorno de desarrollo 🛠',
  preproduction: 'Entorno de pruebas 👨‍🔬'
}
const config = {
  environmentTag:
    textByEnvironment[process.env.REACT_APP_ENV] ||
    textByEnvironment[process.env.NODE_ENV],
  accessTokenKey:
    process.env.REACT_APP_ACCESS_TOKEN_KEY || 'teacherAccessToken',
  host: window.location.host,
  apiURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  logWebRTC: process.env.REACT_APP_LOG_WEB_RTC || '',
  videoTourURL:
    process.env.REACT_APP_VIDEO_TOUR_URL ||
    'https://www.youtube.com/embed/2O8HqTLT5sw', //TODO
  isVideoTourViewedKey:
    process.env.REACT_APP_IS_TUTORIAL_VIEWED_KEY || 'isVideoTourViewed',
  hasMediaPermissionsKey:
    process.env.REACT_APP_HAS_MEDIA_PERMISSIONS_KEY || 'hasMediaPermissions',
  whatsAppNumber: process.env.REACT_APP_WHATS_APP_NUMBER || '34648140199',
  brain: {
    token: process.env.REACT_APP_BRAIN_TOKEN,
    url:
      process.env.REACT_APP_BRAIN_API_URL ||
      'https://api.brain.mundoestudiante.cloud'
  },
  google: {
    authUrl: process.env.REACT_APP_GOOGLE_AUTH_URL || 'http://localhost:5050',
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
  }
}

export default config
