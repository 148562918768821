import React, { useState } from 'react'
import { noop } from 'utils'
import Phone from 'assets/icons/Phone'
import { H4Light } from 'components/typography'
import Button from './Button'
import styles from './HangingUp.module.css'

function HangingUp({ onHangUp = noop }) {
  const [showDialog, setShowDialog] = useState(false)

  const handleCancel = e => {
    e.stopPropagation()
    setShowDialog(false)
  }

  const handleOk = e => {
    e.stopPropagation()
    onHangUp()
    setShowDialog(false)
  }

  return (
    <div className={styles.hangingUpButton} onClick={() => setShowDialog(true)}>
      {showDialog && (
        <div className={styles.dialog}>
          <H4Light className={styles.h4Light}>¿Estás seguro?</H4Light>
          <div className={styles.actions}>
            <div>
              <Button
                label='No'
                size='tiny'
                type='secondary'
                onClick={handleCancel}
              />
            </div>
            <Button label='Sí' size='tiny' onClick={handleOk} />
          </div>
        </div>
      )}
      <Phone className={styles.icon} />
    </div>
  )
}

export default HangingUp
