import React from 'react'
import TimerIcon from 'assets/icons/Timer'
import styles from './Timer.module.css'

const STRONG_TIMER_LIMIT = 1

function Timer({
  seconds,
  active,
  limit = STRONG_TIMER_LIMIT,
  color,
  className,
  size = 'medium'
}) {
  if (seconds < 60 || active) return null
  const timeMinutes = Math.floor(seconds / 60)
  const timeSeconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
  const showStrong = timeMinutes >= limit
  const textToShow = `${timeMinutes}"`
  const colorToUse =
    color || (showStrong ? 'var(--error-color)' : 'var(--purpletronic)')
  return (
    <div className={[styles[size], className].join(' ')}>
      <TimerIcon color={colorToUse}>
        <>
          <text
            className={[
              styles.timerSeconds,
              showStrong ? styles.timerSecondsStrong : ''
            ].join(' ')}
            fill={colorToUse}
            x='50%'
            y='75%'
          >
            {textToShow}
          </text>
        </>
      </TimerIcon>
    </div>
  )
}

export default Timer
