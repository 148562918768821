//@ts-check
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import config from 'config'
import BrightClassfy from 'assets/logos/main/BrightClassfy'
import useLocalStorage from 'hooks/useLocalStorage'
import useNavigation from 'hooks/useNavigation'
import { H2, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import styles from './VideoTour.module.css'

function VideoTour() {
  const navigate = useNavigation()
  const { saveItem } = useLocalStorage(config.isVideoTourViewedKey, false)
  const handleClick = useCallback(() => {
    saveItem(true)
    navigate('/academy/media-permissions')
  }, [navigate, saveItem])

  return (
    <div>
      <Link to='/'>
        <BrightClassfy className={styles.classfyLogo} />
      </Link>
      <section className={styles.section}>
        <div className={styles.videoWrapper}>
          <iframe
            title='Video tour'
            className={styles.iframe}
            frameBorder='0'
            src={config.videoTourURL + '?rel=0'}
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
        <H2 className={styles.bright}>
          ¿Que <span className={styles.seadapted}>cómo funciona</span> esto?
        </H2>
        <Paragraph type='body1Bold' className={styles.bright}>
          Dale al play y aprende cómo usar{' '}
          <span className={styles.seadapted}>Classfy</span>. ¡Es súper fácil e
          intuituvo!
        </Paragraph>
        <Button label='Empezar clase' type='secondary' onClick={handleClick} />
      </section>
    </div>
  )
}

export default VideoTour
