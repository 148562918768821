import React, { useCallback } from 'react'
import { noop } from 'utils'
import styles from './Radio.module.css'

function Radio({
  label = '',
  name = '',
  checked = false,
  disabled = false,
  onCheck = noop
}) {
  const handleCheck = useCallback(
    e => {
      e.stopPropagation()
      if (disabled) return
      onCheck({ target: { name, value: !checked } })
    },
    [checked, disabled, name, onCheck]
  )
  return (
    <div
      className={[styles.radio, disabled ? styles.disabled : ''].join(' ')}
      onClick={handleCheck}
    >
      <input
        nam={name}
        type='radio'
        checked={checked}
        disabled={disabled}
        readOnly
      />
      {label && <label className={styles.label}>{label}</label>}
    </div>
  )
}

export default Radio
