import * as React from 'react'

const EraseAll = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 25 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23.71 18H8.257c-.368 0-.737-.156-.973-.44L.335 9.855a1.26 1.26 0 0 1 0-1.712L7.259.441C7.495.156 7.864 0 8.232 0h15.45C24.42 0 25 .57 25 1.297v15.406c.026.726-.553 1.297-1.29 1.297ZM22.392 2.568H8.837L3.073 9l5.764 6.432h13.556V2.568Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M11.373 5.373a1.208 1.208 0 0 1 1.744 0l1.892 1.893 1.893-1.893a1.208 1.208 0 0 1 1.743 0c.473.474.473 1.27 0 1.744l-1.893 1.892 1.893 1.893c.473.473.473 1.27 0 1.743a1.248 1.248 0 0 1-1.743 0l-1.893-1.893-1.892 1.893a1.248 1.248 0 0 1-1.743 0 1.248 1.248 0 0 1 0-1.743l1.892-1.893-1.892-1.892a1.204 1.204 0 0 1 0-1.744Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default EraseAll
