import React from 'react'
import ReactDOM from 'react-dom/client'
import adapter from 'webrtc-adapter'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as process from 'process'
import NotificationProvider from 'context/NotificationProvider'
import App from './App'
import config from 'config'
import './index.css'

window.global = window
window.process = process
window.Buffer = []
console.log(adapter.browserDetails.browser)

Sentry.init({
  dsn: 'https://8893aa8c416943cdbccdee66c8f07e54@o1069501.ingest.sentry.io/6064187',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <GoogleOAuthProvider clientId={config.google.clientId}>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </GoogleOAuthProvider>
)
