//@ts-check
import React, { useCallback } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import useNavigation from 'hooks/useNavigation'
import useLocalStorage from 'hooks/useLocalStorage'
import config from 'config'
import Phone from 'assets/svg/Phone'
import BrightClassfy from 'assets/logos/main/BrightClassfy'
import { H2, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import styles from './MediaPermissions.module.css'

function MediaPermissions() {
  /**@type {import('hooks/useWebRTC').WebRTCState} */
  const { initUserMedia } = useOutletContext()
  const { saveItem } = useLocalStorage(config.hasMediaPermissionsKey, false)
  const navigate = useNavigation()
  const handleClick = useCallback(async () => {
    try {
      await initUserMedia()
      saveItem(true)
      navigate('/academy')
    } catch (error) {
      console.error('Error granting access: ', error)
      //TODO: use especific notification
    }
  }, [initUserMedia, navigate, saveItem])

  return (
    <div>
      <Link to='/'>
        <BrightClassfy className={styles.classfyLogo} />
      </Link>
      <section className={styles.section}>
        <div className={styles.images}>
          <Phone className={styles.backgroundSvg} color='var(--sandground)' />
          <Phone className={styles.mainSvg} />
        </div>
        <H2 className={styles.h2}>
          Antes de nada habrá que{' '}
          <span className={styles.seadapted}>verse</span> y <br />
          <span className={styles.sandground}>escucharse</span> 😝
        </H2>
        <Paragraph type='body1Bold' className={styles.description}>
          Configura tu cámara y tu micrófono para que tu clase sea una
          experiencia sin cortes
        </Paragraph>
        <Button label='Dar acceso' type='secondary' onClick={handleClick} />
        <Paragraph className={styles.hint}>
          Si no tienes claro cómo configurarlo <span>picha aquí</span>
        </Paragraph>
      </section>
    </div>
  )
}

export default MediaPermissions
