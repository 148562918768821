import LoginSvg from './Login'
import UupsSvg from './Uups'
import NoWorriesSvg from './NoWorries'
import EasyAndCoolSvg from './EasyAndCool'
import HeySvg from './Hey'
import WelcomeSvg from './Welcome'
import NeedHelpSvg from './NeedHelp'
import FeelingGoodSvg from './FeelingGood'
import FeelingGoodColoredSvg from './FeelingGoodColored'
import CoolAndSmartSvg from './CoolAndSmart'
import PhoneSvg from './Phone'
import AlmostReadySvg from './AlmostReady'
import BrainFartSvg from './BrainFart'

export default {
  LoginSvg,
  UupsSvg,
  NoWorriesSvg,
  EasyAndCoolSvg,
  HeySvg,
  WelcomeSvg,
  NeedHelpSvg,
  FeelingGoodSvg,
  FeelingGoodColoredSvg,
  CoolAndSmartSvg,
  PhoneSvg,
  AlmostReadySvg,
  BrainFartSvg
}
