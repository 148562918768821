import * as React from 'react'

const Video = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M34.492 9.964A11.323 11.323 0 0 0 25.166.64a46.297 46.297 0 0 0-15.332 0 11.14 11.14 0 0 0-5.98 3.11l-.065.066A11.095 11.095 0 0 0 .64 9.849a46.379 46.379 0 0 0 0 15.36 11.322 11.322 0 0 0 9.326 9.297c2.512.42 5.055.633 7.603.637a46.557 46.557 0 0 0 7.712-.647 11.144 11.144 0 0 0 6.001-3.067l.066-.066a11.078 11.078 0 0 0 3.149-6.034c.423-2.558.632-5.147.625-7.74a46.734 46.734 0 0 0-.63-7.625Zm-4.345 14.614a6.76 6.76 0 0 1-1.914 3.675 6.724 6.724 0 0 1-3.676 1.914 41.89 41.89 0 0 1-6.988.587 42.197 42.197 0 0 1-6.901-.548 6.908 6.908 0 0 1-5.716-5.716 41.969 41.969 0 0 1 0-13.89 6.766 6.766 0 0 1 1.914-3.674 6.726 6.726 0 0 1 3.676-1.915 41.913 41.913 0 0 1 13.883 0 6.906 6.906 0 0 1 5.76 5.677c.372 2.282.555 4.59.548 6.901 0 2.342-.196 4.68-.587 6.989Z'
      fill={props.color || '#fff'}
    />
    <path
      d='m23.74 12.998-5.952-3.472a5.255 5.255 0 0 0-7.898 4.537v7.054a5.248 5.248 0 0 0 7.893 4.537l5.957-3.473a5.272 5.272 0 0 0 2.606-4.536v-.11A5.272 5.272 0 0 0 23.74 13Zm-1.782 4.647a.872.872 0 0 1-.428.746l-5.952 3.472a.874.874 0 0 1-.439.115.861.861 0 0 1-.861-.861v-7.054c0-.476.385-.862.861-.862.154 0 .305.039.439.115l5.952 3.473c.264.156.426.44.428.746v.11Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Video
